// .dark-mode .about-section {
//     // background-color: rgba(0, 0, 0, 0.2);
// }



.about-section {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; // Ensures animation doesn’t exceed bounds
  
    h1 {
      position: relative; // Keeps text above animation
      z-index: 1;
      font-size: 3rem;
      text-align: left;
    }
  
    &::after {
      content: ""; // Allows for potential overlays or visual effects
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2); // Example for adding a semi-transparent overlay
      z-index: 0;
    }
  }
.about-section .content {
    text-align: left;
    z-index: 2;

    .social_icons {
        display: flex;
        flex-direction: row;
        gap: 10px;
        color: white;

        svg {
            font-size: 1.8em;
        }
    }

    .mobile_social_icons {
        display: none;
    }

    p {
        font-size: 1.5em;
        margin-block-start: 0px;
        margin-block-end: 0px;
    }

    h1 {
        font-size: 5em;
        margin-block-start: 0px;
        margin-block-end: 0px;
    }
}

.about-section .image-wrapper { 
    z-index: 2;
    img {
        width: 300px;
        border-radius: 50%;
        padding-right: 0%;
    }
    padding: 2%;
}

.about p {
    text-align: center;
    padding-top: 30%;
    padding-bottom: 30%;
}

.vanta-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

@media screen {
    @media (max-width: 567px) {
        .nav-left{
            padding-top: 20px;
            float:left;
        }

        .nav-left img {
            height: 35px
        }

        .nav-right {
            float:right;
        }

        .nav-right li {
            padding: 10px 15px;
            list-style:none;
            font-size:13px;
            color:white;
            width: 10%;
        }

        .nav-right ul {
            list-style-type: none;
            margin: 0px;
            padding: 0px;
        }

        body {
            width: 100%;
        }

        .about {
            padding-left: 5%;
            padding-right: 5%;
            font-size: 12px;
        }
    }

    @media (max-width:768px) {
        .about-section h1 {
            font-size: 4em !important;
            text-align: left;
        }

        .about-section p {
            text-align: left;
        }

        .container {
            display: initial;
        }

        .social_icons {
            display: none !important;
        }

        .mobile_social_icons {
            padding-top: 10px;
            display: flex !important;
            flex-direction: row;
            gap: 10px;
            color: white;
    
            svg {
                font-size: 1.8em;
            }
        }

        .about-section {
            padding-top: 150px;
            display: block;
            padding-left: 0px;
            padding-right: 0px;
        }

        .about-section .content {
            width: 90%;
            padding-left: 5%;
            padding-right: 5%;
            padding-bottom: 5%;
        }

        .about-section .image-wrapper {
            padding-left: 5%;
            width: 100%;
        }
    }
}